define('ember-calendly/components/calendly-popup-text', ['exports', 'ember-calendly'], function (exports, _emberCalendly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    url: '',
    tagName: 'a',
    click(e) {
      _emberCalendly.default.showPopupWidget(this.get('url'));
      e.preventDefault();
    },
    willDestroyElement() {
      this._super(...arguments);
      _emberCalendly.default.closePopupWidget();
    }
  });
});