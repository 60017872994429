define('ember-calendly/components/calendly-inline', ['exports', 'ember-calendly'], function (exports, _emberCalendly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['calendly-inline-widget'],
    attributeBindings: ['url:data-url', 'styleString:style'],
    styleString: Ember.computed(function () {
      let style = this.get('style');
      let styleAttrs = [];
      for (let k in style) {
        styleAttrs.push(`${k}: ${style[k]}`);
      }
      return Ember.String.htmlSafe(styleAttrs.join('; '));
    }).volatile(),
    didInsertElement() {
      this._super(...arguments);
      if (this.$().length) {
        let [e] = this.$();
        if (e && e.childNodes.length) {
          e.removeChild(e.childNodes[0]);
        }
        _emberCalendly.default.createInlineWidgets();
      }
    }
  });
});