define('ember-calendly/components/calendly-popup-widget', ['exports', 'ember-calendly'], function (exports, _emberCalendly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    color: '#00a2ff',
    text: 'Schedule time with me',
    branding: false,
    didInsertElement() {
      this._super(...arguments);
      _emberCalendly.default.initBadgeWidget(this.getProperties(['url', 'text', 'color', 'branding']));
    },
    willDestroyElement() {
      this._super(...arguments);
      _emberCalendly.default.destroyBadgeWiget();
      _emberCalendly.default.closePopupWidget();
    }
  });
});